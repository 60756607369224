import styled from 'styled-components'

export const StyledForm = styled.form`

  .required {
    color: red;
  }
`

StyledForm.Item = styled.div`

  padding: 5px 0;

  label {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 22px;
    font-weight: 300;
    color: #fff;
  }

`