import Api from './api'

class Email {

  static send(data) {
    return Api.request(`/email/send`, { data, method: 'POST' })
  }
}

export default Email
