import styled from 'styled-components'
import theme from '../../assets/style/theme'

const StyledCard = styled.section`

  padding: ${({ full }) => full ? '0' : '20px 10%'};

  background: ${({ background }) => theme[background]};

  position: relative;

`

StyledCard.Container = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export {
  StyledCard
}
