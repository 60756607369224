import React from 'react'
import { StyledTitle } from './styled'

const Title = ({ children, id, color }) => (
  <StyledTitle id={id} color={color}>{children}</StyledTitle>
)

Title.defaultProps = {
  color: 'primary',
}

export default Title
