import styled from 'styled-components'

export const StyledTint = styled.div`
  background: rgba(0,0,0,0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
`

export const StyledPattern = styled.div`
  background: url("src/assets/img/pattern.png") repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
`