import React from 'react'
import { StyledTypography } from './styled'

const Typography = ({ children, align, color, size, ...otherProps }) => (
  <StyledTypography
    color={color}
    align={align}
    size={size}
    {...otherProps}
  >
      {children}
  </StyledTypography>
)

Typography.defaultProps = {
  color: 'primary',
  align: 'left',
  size:  'small'
}

export default Typography