import styled from 'styled-components'
import { screen } from '../../assets/style/media'

export const StyledHeader = styled.header`
  width: 100%;
  background: ${({ theme }) => theme.primary};
  z-index: 9999;
  position: fixed;
  border-bottom: 1px solid white;
`

export const StyledLogo = styled.div`
  position: absolute;
  top: 10px;
`

export const StyledMenuSandwich = styled.div`
  position: absolute;
  right: 0;
  z-index: 4;
  width: 100%;
  padding: 5px 10px;
  background: ${({ theme }) => theme.primary};
  overflow: hidden;
  display: none;
  height: 40px;
  cursor: pointer;

  & > div {
    right: 0;
    width: 25px;
    height: 4px;
    background-color: white;
    margin: 4px 0;
  }

  ${screen.desktop`
    display: block;
    position: fixed;
  `}
`

export const StyledMenu = styled.ul`

  list-style-type: none;
  margin: 0;
  padding: 0 10%;
  overflow: hidden;
  font-size: 1.8em;

  li {
    float: left;
    width: 15%;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    outline: none;
    text-decoration: none;
  }

  li:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.secondary};
    a {
      color: #fff;
    }
  }

  ${screen.desktop`

    display: ${({ show }) => show ? 'block' : 'none'};

    position: fixed;
    z-index: 4;
    background: ${({ theme }) => theme.primary};
    top: 40px;
    overflow: hidden;
    height: 100%;
    width: 100%;

    padding: 0;

    li {
      width: 100%;
    }

    li:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.secondary};
      a {
        color: white;
      }
    }
  `}
`