import styled from 'styled-components'

export const StyledContainer = styled.div`
  position: relative;
  opacity: 0.9;
  padding: 20px 15px 40px 15px;
`

StyledContainer.Title = styled.div`
  font-size: 14px;
`

export const StyledLawyer = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.white};
  text-align: center;

  span {
    display: block;
    line-height: 26px;
    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`

export const StyledCard = styled.div`
`
