import React, { Fragment, Component } from 'react'
import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'
import Overlay from 'pigeon-overlay'
import './style.css'
import { StyledContainer } from './styled'
import { Title, Grid } from 'components'
import imgLocation from 'assets/img/location-2.svg'
import { Parallax } from 'react-parallax'


class WhereAreWe extends Component {

  handleMaps = () => {
    const url = 'https://www.google.com.br/maps/place/Advocacia+Roberto+Matos+de+Brito+e+Associados/@-18.922385,-48.269152,15z/data=!4m5!3m4!1s0x0:0x192e5fb0802e1342!8m2!3d-18.922385!4d-48.269152'
    window.open(url, '_blank')
  }

  render() {
    return (
      <Fragment>
        <StyledContainer>
          <Title color="white" id="onde-estamos">Onde Estamos</Title>
          <div className="pd-bottom-20">
            <Grid container>
              <Grid item md={6} xs={12}>
                <StyledContainer.Maps>
                  <div style={{ height: '100%', width: '100%' }}>
                    <Map
                      center={[-18.926460, -48.272190]}
                      zoom={14}
                      touchEvents={false}
                      mouseEvents={false}
                      metaWheelZoom={false}
                    >
                      <Marker
                        anchor={[-18.926460, -48.272190]}
                        payload={1}
                        boxClassname
                        touchEvents={false}
                        mouseEvents={false}
                        metaWheelZoom={false}
                      />
                      <Overlay
                        anchor={[-18.926460, -48.272190]}
                        offset={[120, 79]}
                      >
                        <div>
                          <span className="maps--text">
                            Advocacia Roberto Matos de Brito e Associados
                          </span>
                        </div>
                      </Overlay>
                    </Map>
                  </div>
                </StyledContainer.Maps>
              </Grid>

              <Grid item md={6} xs={12}>
                <StyledContainer.Address>
                  <h3>Advocacia Roberto Matos de Brito e Associados</h3>
                  <p>
                    <img alt="location" width="18px" src={imgLocation} /> Uberlândia CEP: 38408-394 <br/>
                    End. R. Guaicurus, nº 01 <br/>
                    Bairro Vigilato Pereira
                  </p>
                </StyledContainer.Address>
              </Grid>
            </Grid>
          </div>
        </StyledContainer>
        <Parallax
          blur={{min: -5, max: 5 }}
          bgImage={require('assets/img/office/img4.jpg')}
          bgImageAlt="Advocacia Roberto Matos de Brito e Associados - Hall"
          strength={0}
        >
          <div className="parallax-img-height" />
        </Parallax>

      </Fragment>
    )
  }
}


export default WhereAreWe
