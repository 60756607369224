import React, { Fragment } from 'react'
import { StyledCard } from './styled'

const Card = ({ children, background, full }) => (
  <Fragment>
    <StyledCard
      full={full}
      background={background}
    >
      <StyledCard.Container>
        {children}
      </StyledCard.Container>
    </StyledCard>
  </Fragment>
)

Card.defaultProps = {
  background: 'white'
}

export default Card