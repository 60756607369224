import React from 'react'
import { StyledFooter } from './styled'
import logo from 'assets/img/logo.png'

const Footer = () => (
  <StyledFooter>
    Copyright © 2018 - Advocacia Roberto Matos de Brito e Associados
    <img className="mg-left-30" src={logo} alt="logo" width="36px" />
  </StyledFooter>
)

export default Footer