export const practiceArea = {
  area: [
    'CÍVEL',
    'EMPRESARIAL',
    'BANCÁRIO',
    'CONSUMIDOR',
    'CONTRATOS',
    'TRABALHISTA',
    'TRIBUTÁRIO',
    'DUE DILIGENCE',
    'RECUPERAÇÃO DE CRÉDITO',
    'COMPLIANCE',
    'SOCIETÁRIO',
    'FAMÍLIA',
    'DIREITO DIGITAL',
    'DIREITO MÉDICO E HOSPITALAR'
  ]
}
