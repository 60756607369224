import styled from 'styled-components'

export const StyledAddress = styled.div`
  color: #fff;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  overflow-wrap: break-word;
`
