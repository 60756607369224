import styled from 'styled-components'

const StyledCard = styled.div`
  flex-direction: row;
  align-content: space-between;
  flex-flow: row wrap;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
`

StyledCard.Letter = styled.div`
  font-size: 1.8em;
  color: ${({ theme }) => theme.highlight};
  padding-bottom: 10px;
  font-weight: bold;
`

StyledCard.List = styled.ul`
  text-decoration: none;
  list-style: none;
  padding:  0 20px 20px 0;
  margin: 0;
`

StyledCard.Personal = styled.li`
  text-align: left;
  color: ${({ theme }) => theme.primary};
  font-size: 1.2em;
  line-height: 1.2em;
  letter-spacing: 1px;
`

export {
  StyledCard
}