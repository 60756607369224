import React, { Fragment, Component } from 'react'
import { Title, Modal, Grid } from 'components'
import { StyledContainer, StyledCard, StyledLawyer } from './styled'
import { team } from '../../constants/team'


class Team extends Component {

  state = {
    isOpen: false,
    history: '',
    title: '',
  }

  handleCloseModal = () => {
    this.setState({ isOpen: false })
  }

  handleOpenModal = (history, title ) => {
    this.setState({
      isOpen: true,
      history,
      title
    })
  }

  renderTeam = (element, key) => {
    const { history, name, title } = element
    return (
      <Fragment key={`associated-${key}`}>
        <span
          className="cursor-pointer"
          onClick={() => this.handleOpenModal(history, title )}
        >
          <strong>{name}</strong>
        </span>
      </Fragment>
    )
  }

  renderStaffs = (element, key) => {
    const { name } = element
    return (
      <Fragment key={`staffs-${key}`}>
        <span>
          <strong>{name}</strong>
        </span>
      </Fragment>
    )
  }

  render() {

    const { isOpen } = this.state

    return (
      <StyledContainer>
        <Title color="white" id="equipe">Sócios</Title>
        <Grid container spacing={16}>
      
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <StyledCard>
              <StyledLawyer>
                {team.founders.map(this.renderTeam)}
              </StyledLawyer>
            </StyledCard>
          </Grid>
         <Grid item md={4} xs={12}></Grid>
        </Grid>

        <Modal
          isOpen={isOpen}
          handleCloseModal={this.handleCloseModal}
        >
          <p>
            {this.state.title}
          </p>
          {this.state.history}
        </Modal>

      </StyledContainer>

    )
  }
}

export default Team
