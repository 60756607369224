import styled, { css } from 'styled-components'
import theme from '../../assets/style/theme'

const weight = {
  tiny: css`
    letter-spacing: 1px;
    font-size: 12px;
  `,
  small: css`
    line-height: 28px;
    font-size: 18px;
  `,
  subtitle: css`
    line-height: 28px;
    font-size: 1.2em;
  `,
  medium: css`
    line-height: 52px;
    font-size: 32px;
  `,
  large: css`
    line-height: 72px;
    font-size: 52px;
  `
}

export const StyledTypography = styled.div`

  color: ${({ color }) => theme[color]};

  letter-spacing: 1px;

  ${({ size }) => weight[size]}

  text-align: ${({ align }) => align};

`
