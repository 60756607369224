import React from 'react'
import { StyledOutdoor, StyledLogo } from './styled'
import { Carousel, Pattern } from 'components'

import logo from 'assets/img/logo.png'

import external1 from 'assets/img/office/img5.jpg'
import external2 from 'assets/img/office/img6.jpg'

const Outdoor = () => (
  <StyledOutdoor id="advromas">
    <StyledLogo>
      <img src={logo} />
    </StyledLogo>

    <Pattern />
    <Carousel
      speed={2000}
      autoplay
      withoutControls
      initialSlideHeight={643}
      wrapAround
    >
      <img src={external1} alt="Advocacia Roberto Matos de Brito e Associados" />
      <img src={external2} alt="Advocacia Roberto Matos de Brito e Associados" />
    </Carousel>
  </StyledOutdoor>
)

export default Outdoor