import React, { Component } from 'react'
import Modal from 'react-modal'
import imgClose from '../../assets/img/error.svg'
import './style.css'

Modal.setAppElement('#root')

class ModalComp extends Component {

  render() {
    const { children, isOpen, title, handleCloseModal } = this.props
    return (
      <Modal
        isOpen={isOpen}
        contentLabel={title}
        className="adv-modal"
        onRequestClose={handleCloseModal}
      >
        <div className="adv-modal--container">
          <div className="adv-modal--header">
            <div>
              <img
                alt="close"
                className="cursor-pointer"
                onClick={handleCloseModal}
                src={imgClose}
                width="26px"
              />
            </div>
          </div>
          <div className="adv-modal--body">
            {children}
          </div>
        </div>
      </Modal>
    )
  }
}

export default ModalComp
