import React, { Fragment } from 'react'
import { Title, Grid } from 'components'
import { practiceArea } from '../../constants/practice-area'
import map from 'lodash/map'
import { StyledList, StyledItems } from './styled'
import { Parallax } from 'react-parallax'

const renderArea = (area, key) => (
  <Grid item md={3} key={key}>
    <StyledList>
      {area}
    </StyledList>
  </Grid>
)

const PracticeArea = () => (

  <Fragment>
    <Title id="areas-de-atuacao" color="primary">Áreas de Atuação</Title>

    <Parallax
      blur={{min: -20, max: 20}}
      bgImage={require('assets/img/office/img3.jpg')}
      bgImageAlt="Biblioteca"
      strength={200}
    >
      <StyledItems>
        <Grid container spacing={8} justify="center">
            {map(practiceArea.area, renderArea)}
        </Grid>
      </StyledItems>
    </Parallax>

  </Fragment>

)

export default PracticeArea