import styled from 'styled-components'

export const StyledBox = styled.div`
  width: 250px;
  background: ${({ theme }) => theme.secondary};
  color: white;
  position: relative;


`

StyledBox.Header = styled.div`
  height: 150px;
`

StyledBox.Content = styled.div`
  height: 150px;
  padding: 20px;
`

StyledBox.Footer = styled.div`
  height: 30px;
  padding-left: 20px;

`