import styled, { css } from 'styled-components'
import { fadeIn } from 'assets/style/keyframes'
import { screen } from 'assets/style/media'

export const StyledOutdoor = styled.section`
  position: relative;
  min-height: 643px;

  ${screen.desktop`
    min-height: auto;
  `}
`

const message = css`
  position: absolute;
  z-index: 2;
  color: #fff;
  padding-left: 15%;
  font-style: italic;
`

export const StyledLabel = styled.div`
  font-size: 1.2em;
  bottom: 30%;
  animation: ${fadeIn} 1s ease-in;
  border-bottom: 1px solid;

  ${message}

  ${screen.desktop`
    bottom: 20%;
  `}
`

export const StyledMessage = styled.div`
  bottom: 20%;
  font-size: 2.8em;
  animation: ${fadeIn} 2s ease-in;

  ${screen.desktop`
    font-size: 2.0em;
  `}

  ${message}
`

export const StyledLogo = styled.div`
  position: absolute;
  z-index: 2;

  top: 50%;
  left: 50%;
  margin-top: -135px;
  margin-left: -109px;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  ${screen.desktop`
    width: 100px;
    margin-top: -30px;
    margin-left: -50px;
  `}
`