import React from 'react'
import { StyledMain } from './styled'

import { Card } from '../../components'

import WhoWeAre from '../WhoWeAre'
import Team from '../Team'
import WhereAreWe from '../WhereAreWe'
import ContactUs from '../ContactUs'
import Outdoor from '../Outdoor'
import Footer from '../Footer'
import PractireArea from '../PracticeArea'
import RobertoMatos from '../RobertoMatos'

const Main = () => (
  <StyledMain>
    <Outdoor />
    <Card full background="primary">
      <WhoWeAre />
    </Card>
    <Card full background="secondary">
      <Team />
    </Card>
    <Card full background="white">
      <RobertoMatos />
    </Card>
    <Card full>
      <PractireArea />
    </Card>
    <Card full background="primary">
      <WhereAreWe />
    </Card>
    <Card background="secondary">
      <ContactUs />
    </Card>
    <Footer />
  </StyledMain>
)

export default Main