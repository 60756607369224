export const team = {

  founders: [
    {
      name: 'MARCUS ZAGO DE BRITO',
      title: 'Advogado inscrito na OAB/MG sob o n.º 88.238.',
      history: 'Graduado pela Universidade Federal de Uberlândia, pós-graduado em Direito Tributário pelo Instituto Brasileiro de Estudos Tributários – IBET, pós-graduado em Direito Empresarial (LLM) pela Fundação Getúlio Vargas – Rio de Janeiro e pós-graduado em Direito Processual Civil pela Universidade Anhanguera – Uniderp, componente da Comissão de Processo Eletrônico e Inclusão Digital da 13ª Subseção da Ordem dos Advogados do Brasil - OAB Uberlândia, gestão 2016/2018 e Presidente da Comissão de Processo Eletrônico e Inclusão Digital, gestão 2019. Professor de Deontologia Jurídica e Prática Jurídica em Direito Empresarial em cursos de graduação.'
    },
    {
      name: 'LUCIMEIRE ZAGO DE BRITO',
      title: 'Advogada inscrita na OAB/MG sob o n.º 88.241.',
      history: 'Graduada pela Universidade Federal de Uberlândia, pós-graduada em Direito do Trabalho e Processo do Trabalho pela Universidade Federal de Uberlândia-MG, em Direito Empresarial (MBA em Direito da Economia e da Empresa), em Direito Societário, ambas pela Fundação Getúlio Vargas – Rio de Janeiro e pós-graduanda em Direito Digital e Compliance pelo Instituto Damásio de Direito da Faculdade Ibmec SP. Presidente da Comissão de Direito do Trabalho e Processo do Trabalho da OAB Uberlândia, gestão 2013/2015, Diretora Adjunta, gestão 2016/2018 e Presidente da Comissão Advocacia 4.0 e Compliance, gestão 2019. Professora de Direito do Trabalho e Processo do Trabalho em cursos de graduação e pós-graduação.'
    }
  ],

  associated: [
    {
      name: 'ANA BEATRIZ DE MACEDO',
      title: 'Advogada inscrita na OAB/MG sob o n.º 132.292.',
      history: 'Graduada pelo Centro Universitário do Triângulo - Unitri e pós-graduada em Direito Processual Civil pela Faculdade de Direito Professor Damásio de Jesus.'
    },
    {
      name: 'BÁRBARA CAROLINE A. NUNES BICALHO',
      title: 'Advogada inscrita na OAB/MG sob o n.º 166.516.',
      history: 'Graduada pela Faculdade Pitágoras e pós-graduada em Advocacia Cível pela Escola Superior de Advocacia – ESA/MG.'
    },
    {
      name: 'CRISTIANE SILVA',
      title: 'Advogada inscrita na OAB/MG sob o n.º 166.516.',
      history: 'Graduada pelo Centro Universitário do Triângulo – Unitri e pós-graduada em Advocacia Cível pela Escola Superior de Advocacia – ESA/MG.'
    },
    {
      name: 'DANIELA BACANI RODRIGUES',
      title: 'Advogada inscrita na OAB/MG sob o n.º 212.320.',
      history: 'Graduada pela Universidade do Estado de Minas Gerais.'
    },
    {
      name: 'ERIKA T. GUILHERME DE SOUSA',
      title: 'Advogada inscrita na OAB/MG sob o n.º 97.198.',
      history: 'Graduada pelo Centro Universitário do Triângulo - Unitri e pós-graduada em Direito Tributário pela Universidade Presbiteriana Mackenzie.'
    },
    {
      name: 'LUNMAR ALEJANDRO D. R. VARAS CAMPILLAY',
      title: 'Advogado inscrito na OAB/MG sob o n.º 191.774.',
      history: 'Graduado pelo Centro Universitário do Triângulo - Unitri (2018); Pós-Graduado em Direito Penal, Lato Sensu pelo Damásio (2020); Pós Graduado em Direito Civil e Processo Civil com Ênfase em Empreendedorismo Jurídico pelo Instituto Pró Jurídico Democrático (2021).'
    },
    {
      name: 'MÁRCIO R. CINTRA JÚNIOR',
      title: 'Advogado inscrito na OAB/MG sob o n.º OAB/MG 211.625.',
      history: 'Graduado pela Faculdade Santa Rita de Cássia - UNIFASC'
    },
    {
      name: 'SELMIRA MARIA PAMPLONA VIANNA',
      title: 'Advogada inscrita na OAB/MG sob o n.º 64.676.',
      history: 'Graduada pela Universidade Federal de Uberlândia e pós-graduada em Direito do Trabalho pela Universidade Norte do Paraná.'
    },
    {
      name: 'SILVIA MARIA CAGGIANO DE LIMA',
      title: 'Advogada inscrita na OAB/MG sob o n.º 76.320.',
      history: 'Graduada pela Universidade de Uberaba, pós-graduada em Direito Processual Civil, Direito do Trabalho e Processo do Trabalho pela Universidade Federal de Uberlândia. Professora de Direito Processual do Trabalho em curso de graduação. Pós graduanda em Direito Médico e Hospitalar'
    }
  ],

  staffs: [
    {
      name: 'GABRIELE MARTINS FERREIRA',
      title: 'Assistente Administrativo',
      history: ''
    },
    {
      name: 'MARLUCE DINIZ',
      title: 'Assistente Administrativo',
      history: ''
    },
    {
      name: 'NIVALDO SILVA MENDONÇA',
      title: 'Assistente Administrativo',
      history: ''
    },
  ]
}