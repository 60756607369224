import React, { Fragment } from 'react'
import { StyledCard } from './styled'

const List = (element, index) => (
  <StyledCard.Personal key={`personal-${index}`}>{element}</StyledCard.Personal>
)

const renderCards = ({ letter, names }, index) => (
  <Fragment key={`list-names${index}`}>
    <StyledCard.List>
      <StyledCard.Letter>{letter}</StyledCard.Letter>
      {names.map(List)}
    </StyledCard.List>
  </Fragment>
)

const CardNames = ({ list }) => (
  <StyledCard>
    {list.map(renderCards)}
  </StyledCard>
)

CardNames.defaultProps = {
  list: []
}

export default CardNames