import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding: 50px 5%;
  p {
    color: #000;
  }

  img {
    max-height: 750px;
    max-width: 100%;
  }
`