import React, { Component } from 'react'
import { StyledHeader, StyledMenu, StyledMenuSandwich, StyledLogo } from './styled'
import logo from 'assets/img/logo.png'

class Header extends Component {

  state = {
    show: false
  }

  toggleMenu = () => {
    const { show } = this.state

    this.setState({
      show: !show
    })
  }

  render() {

    return (

      <StyledHeader>

        <StyledLogo>
          <a href="#advromas">
            <img className="mg-left-30" src={logo} alt="logo" width="36px" />
          </a>
        </StyledLogo>

        <StyledMenuSandwich onClick={this.toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </StyledMenuSandwich>

        <StyledMenu show={this.state.show}>
          <li>
            <a href="#quem-somos">Quem somos</a>
          </li>
          <li>
            <a href="#areas-de-atuacao">Áreas de atuação</a>
          </li>
          <li>
            <a href="#equipe">Equipe</a>
          </li>
          <li>
            <a href="#roberto-matos">Dr. Roberto Matos</a>
          </li>
          <li>
            <a href="#onde-estamos">Onde estamos</a>
          </li>
          <li>
            <a href="#fale-conosco">Fale conosco</a>
          </li>
        </StyledMenu>
      </StyledHeader>
    )
  }



}

export default Header
