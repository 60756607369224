import React, { Fragment } from 'react'
import { Title, Typography, Grid } from 'components'
import SeloImage from 'assets/img/selo-premiacao.png'
import { StyledContainer } from './styled'
import { Parallax } from 'react-parallax'

const WhoWeAre = () => (

  <Fragment>
    <StyledContainer>
      <Title color="white" id="quem-somos">Quem Somos</Title>
      <Grid container >
        <Grid item xs={12} md={4} className="text-center">
          <img src={SeloImage} alt="Melhores 2019" title="Melhores 2019" />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography align="justify" size="small" color="white">
            <p>
              O escritório de advocacia Roberto Matos de Brito e Associados construiu, ao longo de mais de 04 décadas, uma história de solidez, 
              credibilidade e excelência na prestação de serviços jurídicos, 
              com uma equipe multidisciplinar e especializada em diversas áreas do Direito.
            </p>
            <p>
              Além da esfera contenciosa, a Advocacia Roberto Matos de Brito e Associados também presta consultoria na área preventiva para pequenas, médias e grandes empresas, 
              possuindo sólidas parcerias com grandes e renomados escritórios de advocacia atuantes no âmbito nacional e internacional, 
              o que lhe possibilita dar ampla assessoria a seus clientes.
            </p>
            <p>
              Inscrita na Ordem dos Advogados do Brasil, Seção de Minas Gerais, sob o nº 513, e funcionando em sede própria localizada na cidade de Uberlândia/MG, 
              a Advocacia Roberto Matos de Brito e Associados encontra-se sob administração e coordenação conjunta dos sócios Marcus Zago de Brito e Lucimeire Zago de Brito,
              os quais contam com mais de 20 anos de experiência.
            </p>
            <p>
              A missão de oferecer serviços jurídicos de qualidade e personalizados aos nossos clientes mediante a busca contínua de aperfeiçoamento profissional e os valores como ética, 
              profissionalismo, comprometimento, eficiência, pontualidade e entusiasmo, independentemente do porte da causa, são legados deixados por nosso fundador 
              Dr. Roberto Matos de Brito, cujos ensinamentos são, honrosamente, os pilares da atuação de nossa equipe
            </p>
          </Typography>
        </Grid>
      </Grid>
    </StyledContainer>
    <Parallax
      blur={{min: -20, max: 20 }}
      bgImage={require('assets/img/office/img1.jpg')}
      bgImageAlt="Advocacia Roberto Matos de Brito e Associados - Hall"
      strength={50}
    >
      <div className="parallax-img-height" />
    </Parallax>
  </Fragment>

)

export default WhoWeAre

