import styled from 'styled-components'

export const StyledTextarea = styled.textarea`

  border: 0;
  margin: 0;
  padding: 5px;
  display: block;
  width: 100%;
  height: 200px;
  max-height: 200px;
  box-sizing: content-box;
  background: #fff;
  vertical-align: middle;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: rgba(108, 108, 108, 0.5) 0px 0.5px 5px 0px;
`