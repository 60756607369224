import React, { Fragment } from 'react'
import { Title, Form, Input, Textarea, Button, Grid } from 'components'
import imgMail from 'assets/img/mail.svg'
import imgPhone from 'assets/img/phone.svg'
import { StyledAddress } from './styled'
import Store from './store'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const notify = (message) => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER
  })
};

const handleSubmit = (event) => {

  const form = document.querySelector('#form-contact')
  const fields = {
    name: form[0].value,
    phone: form[1].value,
    email: form[2].value,
    description: form[3].value
  }
  event.preventDefault()
  Store.submit(fields)
    .then((data) => {
      document.querySelector("#form-contact").reset()
      notify(data.message)
    })
}

const ContactUs = () => (

  <Fragment>
    <Title color="white" id="fale-conosco">Fale Conosco</Title>
    <Grid container spacing={16}>
        <Grid item md={6} xs={12}>
          <Form id="form-contact" layout="inline" onSubmit={handleSubmit}>
            <Form.Item>
              <label>Nome:<span className="required">*</span></label>
              <Input placeholder="Nome completo" required />
            </Form.Item>
            <Form.Item>
              <label>Telefone:<span className="required">*</span></label>
              <Input placeholder="(34) 32538811" required/>
            </Form.Item>
            <Form.Item>
              <label>E-mail:<span className="required">*</span></label>
              <Input placeholder="email@email.com" required />
            </Form.Item>
            <Form.Item>
              <label>Comentário:<span className="required">*</span></label>
              <Textarea required />
            </Form.Item>

            <Form.Item>
              <Button>Enviar</Button>
            </Form.Item>
          </Form>
        </Grid>

        <Grid item md={6} xs={12}>
          <StyledAddress>
            <p>
              <img alt="phone" width="18px" src={imgPhone} /> Telefone: (034) 3253-8811 <br/>
              <img alt="phone" width="18px" src={imgPhone} /> Fax: 3253-8810 <br/>
              <img alt="mail" width="18px" src={imgMail} /> contato@robertomatos.adv.br
            </p>
          </StyledAddress>
        </Grid>
    </Grid>
    <ToastContainer hideProgressBar autoClose={2000} />
  </Fragment>

)

export default ContactUs