import styled from 'styled-components'
import { screen } from 'assets/style/media'

export const StyledList = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.primary};
  background: white;
  text-align: center;
  height: 50px;
  opacity: 0.8;

  vertical-align: middle;

  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 0 10px;
`

export const StyledItems = styled.div`
  padding: 150px 5%;

  ${screen.desktop`
    padding: 20px 0;
  `}
`