import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding: 50px 5%;

  img {
    max-height: 450px;
    max-width: 100%;

  }
`