import styled from 'styled-components'

export const StyledFooter = styled.section`
  height: 60px;
  background: ${({ theme }) => theme.primary};
  color: white;
  text-align: center;
  font-size: 14px;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
`
