import React, { Component, Fragment } from 'react';
import { ThemeProvider } from 'styled-components'
import './assets/style/global'
import theme from './assets/style/theme'
import { Header } from './components'

import Main from './container/Main'

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Header />
          <Main />
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default App;
