import styled from 'styled-components'

export const StyledButton = styled.button`

  min-width: 100px;
  height: 36px;
  border: 0;
  border-radius: 10px;
  background: ${({ theme }) => theme.primary};
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.secondary };
    border: 1px solid ${({ theme }) => theme.secondary};
  }

`
