import React from 'react'
import { StyledBox } from './styled'

const Box = ({ children }) => (

  <StyledBox>
    {children}
  </StyledBox>

)

Box.Header = ({ children }) => (

  <StyledBox.Header>
    {children}
  </StyledBox.Header>

)

Box.Content = ({ children }) => (

  <StyledBox.Content>
    {children}
  </StyledBox.Content>

)

Box.Footer = ({ children }) => (

  <StyledBox.Footer>
    {children}
  </StyledBox.Footer>

)

export default Box