import React from 'react'
import { StyledTint } from '../Pattern'
import Carousel from 'nuka-carousel'

import inside1 from 'assets/img/office/img1.jpg'
import inside2 from 'assets/img/office/img2.jpg'
import inside3 from 'assets/img/office/img3.jpg'
import inside4 from 'assets/img/office/img4.jpg'
import external1 from 'assets/img/office/img5.jpg'
import external2 from 'assets/img/office/img6.jpg'

const Hero = () => (
  <div>
    <Carousel>
    <StyledTint />
      <img src={inside1} alt="office" />
      <img src={inside2} alt="office" />
      <img src={inside3} alt="office" />
      <img src={inside4} alt="office" />
      <img src={external1} alt="office" />
      <img src={external2} alt="office" />
    </Carousel>
  </div>
)

export default Hero