import styled from 'styled-components'

const StyledContainer = styled.div`
  width: '100%';
  height: '100vh';
  padding: 0 5%;
`

StyledContainer.Maps = styled.div`
  flex: 1;
  height: 500px;
`

StyledContainer.Address = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.white};
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  justify-content: center;
  padding: 0 10px;
`

export {
  StyledContainer
}