import React, { Fragment } from 'react'
import { Title, Typography, Grid } from 'components'
import RobertoImage from 'assets/img/roberto.png'

import { StyledContainer } from './styled'

const RobertoMatos = () => (
  <Fragment>
    <StyledContainer>
      <Title id="roberto-matos">Nosso Mestre</Title>
      <Typography className="mg-bottom-40"align="center" size="subtitle">Um Legado de Amor e Respeito pelo Direito</Typography>
      <Grid container>
        <Grid item xs={12} md={4} className="text-center">
          <img src={RobertoImage} alt="Dr. Roberto Matos" title="Dr. Roberto Matos" />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography align="justify" size="small">
          <p>
            Em <strong>1978</strong>, Dr. Roberto Matos de Brito graduou-se em Direito pela Universidade Federal de Uberlândia e ao longo de sua jornada especializou-se em Direito Societário, 
            inclusive cursando Especialização na área perante a Fundação Getúlio Vargas.
          </p>
          <p>
            Foi Presidente da 13ª Subseção da Ordem dos Advogados do Brasil - OAB Uberlândia, gestão 1998/2000, Conselheiro Seccional da Ordem dos 
            Advogados do Brasil - Seção Minas Gerais, triênio 2001/2003 e Conselheiro do Primeiro Conselho de Ética e Disciplina da 13ª Subseção da OAB/MG - Uberlândia, 
            Gestão 1995/1997. 
          </p>
          <p>
            Ao longo de sua carreira recebeu diversos títulos: Prêmio “Melhores do Ano - Top 100 AITMAP” - Mérito Profissional, concedido em 29/11/1999 pela AITMAP - 
            Associação e Imprensa do Triângulo Mineiro e Alto Paranaíba; Homenageado pela ABMCJ - Associação Brasileira das Mulheres de Carreira Jurídica Subcomissão do 
            Triângulo Mineiro e Alto Paranaíba, como destaque jurídico nos anos de 1999 e 2002; “Comenda da Ordem Municipal do Mérito Augusto César”, pelos relevantes serviços 
            prestados em prol do desenvolvimento do Município de Uberlândia, concedida em 31 de agosto de 2000; Homenageado pela Ordem dos Advogados do Brasil Seção Minas Gerais 
            com Diploma de Honra ao Mérito pelos relevantes serviços prestados a Entidade, auxiliando no fortalecimento da Advocacia e Cidadania, concedido em 03 de agosto de 2012; 
            Advogado destaque do Ano, na Área do Direito Bancário, concedido pela Câmara Municipal de Uberlândia em 29 de agosto de 2013 e Prêmio “Melhores 2020 - Top 100 AITMAP” - 
            Mérito Profissional, concedido in memorian pela AITMAP - Associação e Imprensa do Triângulo Mineiro e Alto Paranaíba.
          </p>
          <p>
            Em 14 de agosto de 2020, através da Lei Municipal 13.372, em homenagem póstuma pelos relevantes serviços prestados à advocacia e sociedade uberlandense, a 
            sede da Superintendência de Proteção e Defesa do Consumidor - PROCON, em Uberlândia, passou a ser denominada de “Dr. Roberto Matos de Brito”.
          </p>
          <p>
            O destino nos afastou muito cedo da presença física do Dr. Roberto Matos de Brito, mas seus ensinamentos sempre estarão presentes como nosso guia, na vida e na advocacia.
          </p>
          <p>
            Ao nosso mestre, nosso eterno agradecimento.
          </p>
        </Typography>
        </Grid>
      </Grid>
    </StyledContainer>
  </Fragment>

)

export default RobertoMatos

