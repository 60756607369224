import { css } from 'styled-components'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

const sizes = {
  large: 1170,
  desktop: 992,
  tablet: 768,
  mobile: 376,
}

export const toEm = px => `${px / 16}em`

const newSize = size => (...args) => css`
  @media (max-width: ${toEm(size)}) {
    ${css(...args)}
  }
`

export const screen = reduce(sizes, (accumulator, size, label) =>
  ({ ...accumulator, [label]: newSize(size) }), {})

export const hiddenAndVisible = map(screen, (fn, label) => css`
  ${fn`
    .hidden-${label} {
      display: none !important;
    }

    .visible-${label} {
      display: block !important;
    }
  `}
`)
