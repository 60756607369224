import React from 'react'
import { StyledForm } from './styled'

export const Form = ({ children, id, onSubmit }) => (
  <StyledForm id={id} onSubmit={onSubmit}>
    {children}
  </StyledForm>
)

Form.Item = ({ children }) => (
  <StyledForm.Item>
    {children}
  </StyledForm.Item>
)